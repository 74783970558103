import React from 'react'
import Header from '../components/page3/Header'
import Info from '../components/page3/FirstPart'
import { ScrollTop } from "primereact/scrolltop";
import SecondInfo from '../components/page3/SecondPart'
import ThirdInfo from '../components/page3/ThirdPart'
import FourthInfo from '../components/page3/FourthPart'
import Footer from '../components/Footer'



export default function FormPage3() {
  return (
    <>
    <div>
        <Header />
        <div
          style={{ backgroundColor: "rgba(242, 242, 242, 1)", padding: "2rem" }}
        >
          <Info />
        </div>
        <SecondInfo />
        <div
          style={{ backgroundColor: "rgba(242, 242, 242, 1)", padding: "2rem" }}
        >
          <ThirdInfo />
        </div>
        <FourthInfo />
      </div>
      <div className="mt-8">
        <Footer />
      </div>
      <ScrollTop threshold={100} />
   </>
  )
}
