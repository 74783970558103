import React from 'react'
import Form from '../components/Form'
import Footer from '../components/Footer'
import '../assets/css/formPage/formPage.css'

export default function FormPage1() {
    return (
        <div className='FormPage1'>
            <div className='content'>
                <Form />
            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </div>
    )
}
