import { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast'; // Importer le composant Toast
import '../assets/css/form.css';

export default function Form() {
    const [company, setCompany] = useState('');
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [selectedEcheance, setSelectedEcheance] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const toast = useRef(null); // Créer une référence pour le Toast

    const echeanceProjet = [
        { name: "Moins d'un mois", code: '1' },
        { name: 'De 1 à 3 mois', code: '1-3' },
        { name: 'De 3 à 6 mois', code: '3-6' },
        { name: 'De 6 à 12 mois', code: '6-12' },
        { name: 'Plus de 12 mois', code: '12+' }
      ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        const combinedValue = company && selectedEcheance
            ? `${company} | (Echeance : ${selectedEcheance.name})`
            : company || '';

        if (phone.length !== 10) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Le numéro de téléphone doit contenir exactement 10 chiffres.' });
            return;
        }

        if (company && lastname && firstname && phone && email) {
            const formData = {
                company: combinedValue,
                lastname,
                firstname,
                phone,
                email,
                nbUser: '//'
            };
        
        
            try {
                const response = await fetch("https://env-3387615.jcloud-ver-jpe.ik-server.com/api/v1/email/mail-business-pro", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
        
        
                if (response.ok) {
                    toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Formulaire soumis avec succès!' });
                    setCompany('');
                    setLastname('');
                    setFirstname('');
                    setPhone('');
                    setEmail('');
                    setSelectedEcheance(null);
                    setSubmitted(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la soumission du formulaire.' });
                }
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la soumission du formulaire.' });
            }
        } else {
        }
        
    };

    const handleInputChange = (setter) => (e) => {
        const value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''); // Supprime les caractères spéciaux
        setter(value);
    };

    return (
        <div className="flex justify-content-center mt-2">
            <div className='flex flex-column align-items-center justify-content-center form-container'>
                <Toast ref={toast} /> {/* Ajouter le Toast ici */}
                <h2 style={{color:'rgb(6, 166, 229)'}} className='m-0 mb-4'>Recevez un devis gratuit en 24h</h2>
                <form onSubmit={handleSubmit} className="flex flex-column gap-3 w-full">
                    <div  className="p-inputgroup flex-1 c-border">
                        <span className="p-inputgroup-addon bg-color">
                            <i className="pi pi-home c-white"></i>
                        </span>
                        <InputText
                            value={company}
                            onChange={handleInputChange(setCompany)}
                            placeholder="Société"
                            className={submitted && !company ? 'p-invalid' : ''}
                            required
                        />
                    </div>
                    {submitted && !company && <small className="p-error">La société est requise.</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon bg-color">
                            <i className="pi pi-user c-white"></i>
                        </span>
                        <InputText
                            value={lastname}
                            onChange={handleInputChange(setLastname)}
                            placeholder="Nom"
                            className={submitted && !lastname ? 'p-invalid' : ''}
                            required
                        />
                    </div>
                    {submitted && !lastname && <small className="p-error">Le nom est requis.</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon bg-color">
                            <i className="pi pi-user c-white"></i>
                        </span>
                        <InputText
                            value={firstname}
                            onChange={handleInputChange(setFirstname)}
                            placeholder="Prénom"
                            className={submitted && !firstname ? 'p-invalid' : ''}
                            required
                        />
                    </div>
                    {submitted && !firstname && <small className="p-error">Le prénom est requis.</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon bg-color">
                            <i className='pi pi-phone c-white'></i>
                        </span>
                        <InputText
                            keyfilter="num"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ''))} // Supprime les caractères non numériques
                            placeholder="Téléphone"
                            maxLength="10"
                            className={submitted && (!phone || phone.length !== 10) ? 'p-invalid' : ''}
                            required
                        />
                    </div>
                    {submitted && (!phone || phone.length !== 10) && <small className="p-error">Le téléphone est requis.</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon bg-color">
                            <i className='pi pi-envelope c-white'></i>
                        </span>
                        <InputText
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            keyfilter="email"
                            placeholder="Email"
                            className={submitted && !email ? 'p-invalid' : ''}
                            required
                        />
                    </div>
                    {submitted && !email && <small className="p-error">L'email est requis.</small>}

                    <Dropdown
                        value={selectedEcheance}
                        onChange={(e) => setSelectedEcheance(e.value)}
                        options={echeanceProjet}
                        optionLabel="name"
                        placeholder="Echeance"
                        className={`w-full ${submitted && !selectedEcheance ? 'p-invalid' : ''}`}
                        required
                    />
                    {submitted && !selectedEcheance && <small className="p-error">La demande est requise.</small>}

                    <Button rounded raised className='hover-color' type="submit" label='Valider' style={{border:'none'}} />
                </form>
            </div>
        </div>
    );
}
