import { useRef } from 'react'
import Form4 from '../components/Form4'
import '../assets/css/formPage4/formPage4.css'
import check from '../assets/img/icons/check_form.png'
import business from '../assets/img/icons/business.png'
import money from '../assets/img/icons/money.png'
import { Panel } from 'primereact/panel';
import Footer from '../components/Footer'
import logo from '../assets/img/logo_compare.png'

export default function FormPage4() {
  const ref = useRef(null);
  return (
    <div className=''>
      <div className="flex flex-column">
        <div className="flex flex-row flex-wrap justify-content-between align-items-center m-2 ml-8 mr-8">
          <img src={logo} alt="" className='logo-compare' />
        <p className='text-right m-0' style={{ fontWeight: 'bold' }}>Comparez en quelques clics les fournisseurs de Standards Téléphoniques</p>
        </div>
        <div className="flex justify-content-center band-bg mb-8 p-2">
          <div className="form-div">
            <Form4 />
          </div>
        </div>
        <div className="" style={{ marginTop: '8rem' }}>
          <div>
            <h2 style={{ color: 'black' }}>Recevez et comparez les devis pour votre standard téléphonique en quelques clics</h2>
            <div className="flex flex-row flex-wrap gap-7 justify-content-center align-items-center text-center">
              <div className="container-icon">
                <div className="flex flex-column flex-wrap align-items-center">
                  <img className='icon-info' src={check} alt="" />
                  <h3 style={{ color: 'black' }}>1. Décrivez votre besoin</h3>
                  <p>Remplissez notre formulaire en 60 secondes seulement</p>
                </div>
              </div>
              <div className="flex flex-column align-items-center">
                <div className="container-icon">
                  <img className='icon-info' src={business} alt="" />
                  <h3 style={{ color: 'black' }}>2. Service 100% gratuit</h3>
                  <p>Recevez des devis gratuits des principaux opérateurs de téléphonie professionnels</p>
                </div>
              </div>
              <div className="flex flex-column align-items-center">
                <div className="container-icon">
                  <img className='icon-info' src={money} alt="" />
                  <h3 style={{ color: 'black' }}>3. Gagez du temps et économisez de l’argent</h3>
                  <p>Sélectionnez la meilleure offre proposée par les fournisseurs</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-column gap-5 mt-8 justify-content-center align-items-center">
            <Panel className='panel-container text-justify' ref={ref} collapsed={true} header="Qu’est-ce qu’un standard téléphonique ?" toggleable>
              <p className="m-0">
                Le Standard Téléphonique est une solution de communication qui permet d’automatiser et de centraliser les appels entrants d’une entreprise. Il permet de traiter un très grand nombre d’appels et de les gérer de manière professionnelle. Les standards téléphoniques peuvent être utilisés sur des lignes analogiques, numériques, mais aussi sur des ordinateurs et mobiles grâce à des applications.<br /><br />
                Un standard téléphonique peut être adapté à toute entreprise qui reçoit beaucoup d’appels et n’arrive pas à les gérer convenablement. Il peut servir aussi à rediriger les appels vers plusieurs services grâce à la redirection des appels via l’utilisation d’un menu vocal.
              </p>
            </Panel>
            <Panel className='panel-container text-justify' ref={ref} collapsed={true} header="Quel est notre fonctionnement ?" toggleable>
              <p className="m-0">
                Nous comparons plusieurs fournisseurs afin de vous aider à trouver la meilleure offre possible et le tarif le plus avantageux par rapport à vos demandes en terme d’utilisateurs, de fonctionnalités et de type de solution. Il existe un grand nombre de fournisseurs de standards téléphoniques avec des offres parfois complexes à étudier. Nous recommandons particulièrement Standard En Ligne et COLOR TELECOM. Ces fournisseurs proposent tous des standards téléphoniques faciles à utiliser à des tarifs très compétitifs.
              </p>
            </Panel>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Footer />
      </div>
    </div>
  )
}
