import React from 'react'
import Form2 from '../components/Form2'
import Footer from '../components/Footer'

export default function FormPage2() {
    return (
        <div className='FormPage2'>
            <div className='content'>
                <Form2 />
            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </div>
    )
}
