import React from 'react'

export default function ThirdInfo() {
    return (
        <div>
            <div className="flex flex-column justify-content-center align-items-center">
                <h2>Pourquoi choisir notre standard téléphonique virtuel ?</h2>
                <ul className='text-left' style={{ maxWidth: '650px' }}>
                    <li className=''><span className='list-bold-text'>Gérez facilement vos appels</span> entrants depuis votre compte : rapidité de décrochage, redirection des appels vers le bon interlocuteur, disponibilité des interlocuteurs.</li>
                    <li className='mt-2'><span className='list-bold-text'>Gagnez du temps</span> sur la mise en place de votre standard téléphonique en le déployant en quelques minutes.</li>
                    <li className='mt-2'><span className='list-bold-text'>Réduisez vos frais d'investissement.</span> Plus besoin d’investir dans du matériel de standard téléphonique (frais d’installation, maintenance, mises à jour à effectuer…).</li>
                </ul>
            </div>
        </div>
    )
}
