import React from 'react'
import logo from '../../assets/img/logo.png'
import '../../assets/css/formPage3/Header.css'

export default function Header() {
  return (
    <div className="flex justify-content-center bg-color-header">
      <div style={{ width: '800px' }} className="">
        <div className='flex flex-row align-items-center justify-content-between'>
          <img className='w-auto h-full' src={logo} alt="" />
          <p className="button-edit-vert c-white"><i style={{ fontSize: '20px' }} className="pi pi-phone"></i><b> 01 87 200 200</b></p>
        </div>
      </div>
    </div>
  )
}
