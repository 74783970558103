import React from 'react'
import assistant from '../../assets/img/assistant.webp'
import '../../assets/css/formPage3/secondInfo.css'


export default function SecondInfo() {
    return (
        <div>
            <h2>Un standard d'entreprise en quelques miniutes</h2>
            <div className="flex flex-column justify-content-center align-items-center gap-4">
                <img style={{ width: '330px' }} src={assistant} alt="" />
                <div className="flex flex-row flex-wrap align-items-center text-left gap-5">
                    <div className="block-info">
                        <p className='m-2 w-100'>1. Activez votre numéro d'entreprise</p>
                    </div>
                    <div className="block-info">
                        <p className='m-2 w-100'>2. Configurez votre standard</p>
                    </div>
                    <div className="block-info">
                        <p className='m-2 w-100'>3. Gérez vos appels instantanément !</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-column justify-content-center align-items-center">
                <h2>C'est quoi un standard téléphonique virtuel ?</h2>
                <p className='text-justify' style={{maxWidth:"500px"}}>Le standard téléphonique virtuel est une technologie innovante qui offre une flexibilité accrue aux entreprises et à leurs employés. Il fonctionne sur le même principe que le standard téléphonique traditionnel et est géré dans le Cloud via une interface web dédiée.<br /><br/>Grâce au standard téléphonique vous pouvez joindre les collaborateurs au téléphone, même lorsqu'ils ne sont pas au bureau, tout en utilisant le numéro du bureau (numéro unique).


                <br/><br/>Le standard téléphonique virtuel offre une qualité d'accueil optimale, distribue et transfère les appels téléphoniques, et contribue à améliorer l'image de l'entreprise.


                    <br/><br/>Hébergé dans le cloud, le gestionnaire peut installer son standard en toute autonomie et sa gestion quotidienne est extrêmement simple.
                    De plus le standard téléphonique virtuel est un modèle économique intéressant, puisqu'il est proposé en locatif et ne nécessite aucune maintenance.
                    Enfin, il s'adapte aux entreprises de toutes tailles</p>
            </div>
        </div>
    )
}
