// App.jsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import FormPage1 from "./pages/FormPage1";
import FormPage2 from "./pages/FormPage2";
import FormPage3 from "./pages/FormPage3";
import FormPage4 from "./pages/FormPage4";
import Footer from "./components/Footer";
import MentionsLegales from "./pages/MentionsLegales";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/lp1" element={<FormPage1 />} />
          <Route path="/lp2" element={<FormPage2 />} />
          <Route path="/lp3" element={<FormPage3 />} />
          <Route path="/lp4" element={<FormPage4 />} />
          <Route path="/mentions" element={<MentionsLegales />} /> {/* Nouvelle route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
