import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import '../assets/css/form.css';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';


export default function Form2() {
    const [company, setCompany] = useState('');
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState('');

    const priority = [
        { name: 'Projet urgent', code: 'urgent' },
        { name: 'Projet non urgent', code: 'nonUrgent' },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        const combinedValue = company && selectedPriority
            ? `${company} | (Demande : ${selectedPriority.name})`
            : company || '';

        if (phone.length !== 10) {
            setSubmissionMessage('Le numéro de téléphone doit contenir exactement 10 chiffres.');
            return;
        }

        if (company && lastname && firstname && phone && email) {
            const formData = {
                company: combinedValue,
                lastname,
                firstname,
                phone,
                email,
                nbUser: '//'
            };

            try {
                const response = await fetch("https://env-3387615.jcloud-ver-jpe.ik-server.com/api/v1/email/mail-business-pro", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    setSubmissionMessage('Formulaire soumis avec succès!');
                    setCompany('');
                    setLastname('');
                    setFirstname('');
                    setPhone('');
                    setEmail('');
                    setSelectedPriority(null);
                    setSubmitted(false);
                } else {
                    setSubmissionMessage('Erreur lors de la soumission du formulaire.');
                }
            } catch (error) {
                setSubmissionMessage('Erreur lors de la soumission du formulaire.');
            }
        }
    };

    const handleInputChange = (setter) => (e) => {
        const value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''); // Supprime les caractères spéciaux
        setter(value);
    };

    return (
        <>
            <div className="flex justify-content-center mt-3">
                <div className='flex flex-column align-items-center justify-content-center form-container'>
                    <h2 style={{ color: 'rgb(6, 166, 229)' }} className='m-0 mb-4'>MON DEVIS TÉLÉPHONIQUE SUR MESURE</h2>
                    <form onSubmit={handleSubmit} className="flex flex-column gap-3 w-full">
                        <div className="p-inputgroup flex-1">
                            <InputText
                                value={company}
                                onChange={handleInputChange(setCompany)}
                                placeholder="Société"
                                className={submitted && !company ? 'p-invalid' : ''}
                                required
                            />
                        </div>
                        {submitted && !company && <small className="p-error">La société est requise.</small>}
                        <div className="p-inputgroup flex-1">
                            <InputText
                                value={lastname}
                                onChange={handleInputChange(setLastname)}
                                placeholder="Nom"
                                className={submitted && !lastname ? 'p-invalid' : ''}
                                required
                            />
                        </div>
                        {submitted && !lastname && <small className="p-error">Le nom est requis.</small>}
                        <div className="p-inputgroup flex-1">
                            <InputText
                                value={firstname}
                                onChange={handleInputChange(setFirstname)}
                                placeholder="Prénom"
                                className={submitted && !firstname ? 'p-invalid' : ''}
                                required
                            />
                        </div>
                        {submitted && !firstname && <small className="p-error">Le prénom est requis.</small>}
                        <div className="p-inputgroup flex-1">
                            <InputText
                                keyfilter="num"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ''))} // Supprime les caractères non numériques
                                placeholder="Téléphone"
                                maxLength="10"
                                className={submitted && (!phone || phone.length !== 10) ? 'p-invalid' : ''}
                                required
                            />
                        </div>
                        {submitted && (!phone || phone.length !== 10) && <small className="p-error">Le téléphone est requis.</small>}
                        <div className="p-inputgroup flex-1 ">
                            <InputText
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                keyfilter="email"
                                placeholder="Email"
                                className={submitted && !email ? 'p-invalid' : ''}
                                required
                            />
                        </div>
                        {submitted && !email && <small className="p-error">L'email est requis.</small>}
                        <Dropdown
                            value={selectedPriority}
                            onChange={(e) => setSelectedPriority(e.value)}
                            options={priority}
                            optionLabel="name"
                            placeholder="Demande"
                            className={`w-full ${submitted && !selectedPriority ? 'p-invalid' : ''}`}
                            required
                        />
                        {submitted && !selectedPriority && <small className="p-error">La demande est requise.</small>}

                        <Button className='' type="submit" label='Valider' severity='info' />
                    </form>
                    {submissionMessage && <p>{submissionMessage}</p>}
                </div>
            </div>
        </>
    );
}
