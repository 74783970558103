import React from 'react'
import Form from '../Form3'
import '../../assets/css/formPage3/info.css'

export default function Info() {
    return (
        <div className='flex flex-row flex-wrap justify-content-center align-items-center gap-8'>
            <div className="">
                <h1>Le standard téléphonique simple<br /> et rapide à mettre en place</h1>
                <p>Déployez votre standard téléphonique professionnel rapidement et en toute simplicité.</p>
                <div className='flex flex-column align-items-center'>
                    <div className="container-info">
                        <div className="flex flex-row align-items-center gap-4">
                        <i style={{fontSize:'2.5rem'}} className='pi pi-phone'></i>
                            <img src="" alt="" />
                            <div className="flex flex-column text-left w-300">
                                <p className='m-0 p-title'>Toutes les fonctionnalités essentielles d'un standard téléphonique</p>
                                <p > Messages d’accueil, musique d’attente, redirection des appels.</p>
                            </div>
                        </div>
                        <div className="flex flex-row align-items-center text-left gap-5">
                            <i style={{fontSize:'3rem'}} className='pi pi-sliders-h'></i>
                            <div className="flex flex-column text-justify w-300">
                                <p className='p-title'>Une gestion simplifiée des appels</p>
                                <p className='m-0'>Pilotage centralisé des services : gestion du répertoire d’entreprise et configuration de la téléphonie de l'ensemble de vos sites.</p>
                            </div>
                        </div>
                        <div className="flex flex-row align-items-center text-left gap-5">
                        <i style={{fontSize:'3rem'}} className='pi pi-cloud'></i>
                        <div className="flex flex-column text-justify w-300">
                                <p className='p-title'>Un standard hébergé dans le Cloud</p>
                                <p className='m-0'>Installation simple et mise à jour transparente de logiciels dans le respect de votre budget.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <Form />
            </div>
        </div>
    )
}
