import React from 'react'

export default function FourthInfo() {
    return (
        <div className="flex flex-column justify-content-center align-items-center">
            <h2>Les fonctionnalités que nous proposons</h2>
            <div className='text-justify' style={{ maxWidth: "500px" }}>
                <p><span className='p-title'>Accueil téléphonique professionnel</span><br />
                    Annonce d’accueil entièrement personnalisable. Gestion des appels simultanés avec mise en attente ou redirection vers différents collaborateurs.
                </p>
                <p><span className='p-title'>Transfert d'appels et renvoi automatique des appels</span><br />
                    Redirection des appels entrants vers un autre numéro de téléphone. Le transfert d’appels peut être configuré pour se faire automatiquement en fonction de divers critères (heure de la journée, identité de l'appelant, disponibilités de l'appelé).
                </p>
                <p><span  className='p-title'>Serveur Vocal Interactif (SVI)</span><br />
                    Traitement intelligent des appels entrants, avec ou sans diffusion de messages préenregistrés. Le SVI permet à l’appelant de sélectionner l’objet de son appel ou le service auquel il souhaite s’adresser.
                </p>
                <p><span className='p-title'>Groupe d'appel</span><br />
                    Redirection des appels entrants vers un ensemble de lignes téléphoniques afin de ne plus perdre d'appels. Chaque collaborateur du groupe d'appels reçoit les appels sur son poste selon des règles préalablement définies.
                </p>
            </div>
        </div>
    )
}
