import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/footer.css';

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div className='flex flex-row gap-8 justify-content-center p-3 footer-bg'>
            <p>© {currentYear} COLOR TELECOM SAS. Tous droits réservés.</p>
            <Link to="/mentions" style={{ color: 'white', textDecoration: 'none' }}>
                <p style={{ cursor: 'pointer' }}>Mentions légales</p>
            </Link>
        </div>
    );
}
