import React, { useRef, useState } from 'react';
import { Stepper } from 'primereact/stepper';
import { InputText } from 'primereact/inputtext';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { SelectButton } from 'primereact/selectbutton';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import '../assets/css/form4.css';

export default function Form4() {
    const toast = useRef(null);
    const stepperRef = useRef(null);
    const [hasUsedPhone, setHasUsedPhone] = useState(null);
    const [numberOfUsers, setNumberOfUsers] = useState(null);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [postalCode, setPostalCode] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [progress, setProgress] = useState(0);

    const handlePhoneSelectionChange = (e) => {
        setHasUsedPhone(e.value);
    };

    const handleUsersChange = (value) => {
        setNumberOfUsers(value);
    };

    const handleFeatureChange = (value) => {
        setSelectedFeatures((prevFeatures) =>
            prevFeatures.includes(value)
                ? prevFeatures.filter((feature) => feature !== value)
                : [...prevFeatures, value]
        );
    };

    const handlePostalCodeChange = (e) => {
        setPostalCode(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleNext = (currentStep) => {
        const stepsProgress = [14, 28, 42, 56, 70, 85, 100];
        if (currentStep === 1 && hasUsedPhone !== null) {
            stepperRef.current.nextCallback();
            setProgress(stepsProgress[currentStep - 1]);
        } else if (currentStep === 2 && numberOfUsers !== null) {
            stepperRef.current.nextCallback();
            setProgress(stepsProgress[currentStep - 1]);
        } else if (currentStep === 3 && selectedFeatures.length > 0) {
            stepperRef.current.nextCallback();
            setProgress(stepsProgress[currentStep - 1]);
        } else if (currentStep === 4 && postalCode.trim() !== '') {
            stepperRef.current.nextCallback();
            setProgress(stepsProgress[currentStep - 1]);
        } else if (currentStep === 5 && email.trim() !== '' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            stepperRef.current.nextCallback();
            setProgress(stepsProgress[currentStep - 1]);
        } else if (currentStep === 6 && firstName.trim() !== '' && lastName.trim() !== '' && companyName.trim() !== '') {
            stepperRef.current.nextCallback();
            setProgress(stepsProgress[currentStep - 1]);
        } else if (currentStep === 7 && phoneNumber.trim() !== '' && /^[\d\s\+\-()]{7,15}$/.test(phoneNumber)) {
            submitForm();
            setProgress(stepsProgress[currentStep - 1]);
        }
    };

    const submitForm = async () => {
        const combinedValue = companyName && numberOfUsers && postalCode && hasUsedPhone && selectedFeatures
            ? `${companyName} | Code Postal: ${postalCode} | Déjà utilisé standard : ${hasUsedPhone} | Fonctionnalités : ${selectedFeatures}`
            : companyName || '';

        const formData = {
            firstname: firstName,
            lastname: lastName,
            email: email,
            phone: phoneNumber,
            company: combinedValue,
            nbUser: numberOfUsers,
        };

        try {
            const response = await fetch('https://env-3387615.jcloud-ver-jpe.ik-server.com/api/v1/email/mail-business-pro', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi des données.');
            }

            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Données envoyées avec succès ! La page va se recharger dans quelques secondes', life: 5000 });
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'envoi des données.' });
        }
    };

    const isArrayEmpty = (arr) => arr.length === 0;

    return (
        <div className="flex flex-column justify-content-center align-items-center">
            <Toast ref={toast} />
            <div className="stepper-container p-4" style={{ width:'100%'}}>
                <div className="" style={{ width: '100%'}}>
                    <ProgressBar className='progress-bar mt-4' value={progress} />
                </div>
                <Stepper ref={stepperRef} className="w-full">
                    <StepperPanel header="">
                        <div className="flex flex-column">
                            <div className="p-field">
                                <h3 className='title-step' htmlFor="hasUsedPhone">Possédez-vous déjà un standard téléphonique ?</h3>
                                <div className="mt-5">
                                    <SelectButton
                                        value={hasUsedPhone}
                                        options={[
                                            {
                                                label: (
                                                    <div className='selectbutton-step' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="flex flex-column justiy-content-center align-items-center">
                                                            <i style={{ fontSize: '30px' }} className='pi pi-check'></i>
                                                            Oui
                                                        </div>
                                                    </div>
                                                ),
                                                value: 'oui'
                                            },
                                            {
                                                label: (
                                                    <div className='selectbutton-step' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="flex flex-column justiy-content-center align-items-center">
                                                            <i style={{ fontSize: '30px' }} className='pi pi-times'></i>
                                                            Non
                                                        </div>
                                                    </div>
                                                ),
                                                value: 'non'
                                            }
                                        ]}
                                        onChange={handlePhoneSelectionChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-content-center pt-4 justify-content-end">
                            <Button
                                label="Continuer"
                                className='w-full'
                                onClick={() => handleNext(1)}
                                disabled={hasUsedPhone === null}
                            />
                        </div>
                        <p className='m-0 mt-3'><i className='pi pi-stopwatch'></i> Complétez notre formulaire en seulement 60 secondes</p>
                    </StepperPanel>
                    <StepperPanel header="">
                        <div className="flex flex-column">
                            <div className="p-field">
                                <h3 className='title-step' htmlFor="numberOfUsers">Nombre d’utilisateurs qui utilisent la téléphonie dans votre entreprise :</h3>
                                <div className="flex flex-column justify-content-left gap-3 align-items-center p-formgroup-inline">
                                    {[
                                        { label: '1 à 3', value: '1-3' },
                                        { label: '4 à 10', value: '4-10' },
                                        { label: '11 à 20', value: '11-20' },
                                        { label: '21 à 50', value: '21-50' },
                                        { label: '51 à 100', value: '51-100' },
                                        { label: 'plus de 100', value: '100+' }
                                    ].map((option) => (
                                        <div
                                            className="flex gap-2 justify-content-left align-items-center p-field-radiobutton p-form-step-2 w-full cursor-pointer"
                                            key={option.value}
                                            onClick={() => handleUsersChange(option.value)} // Handle click on div
                                        >
                                            <RadioButton
                                                id={`user-${option.value}`}
                                                name="numberOfUsers"
                                                className=''
                                                value={option.value}
                                                checked={numberOfUsers === option.value}
                                            />
                                            <label className='cursor-pointer' htmlFor={`user-${option.value}`}>{option.label}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex pt-4 gap-3 mt-2">
                            <Button
                                iconPos="left"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                onClick={() => stepperRef.current.prevCallback()}
                                className="previous-button"
                            />
                            <Button
                                label="Continuer"
                                className='w-full'
                                onClick={() => handleNext(2)}
                                disabled={numberOfUsers === null}
                            />
                        </div>
                        <p className='m-0 mt-3'><i className='pi pi-stopwatch'></i> 40 secondes restantes...</p>
                    </StepperPanel>
                    <StepperPanel header="">
                        <div className="flex flex-column">
                            <div className="p-field">
                                <h3 className='title-step' htmlFor="selectedFeatures">Quelles sont les fonctionnalités que vous recherchez ?</h3>
                                <div className="flex flex-column gap-3 align-items-start p-formgroup-inline mt-3">
                                    {[
                                        { label: 'Serveur Vocal Interactif', value: 'serveur_vocal_interactif' },
                                        { label: 'Redirections d’appels', value: 'redirections_appel' },
                                        { label: 'Intégration avec votre logiciel CRM', value: 'integration_crm' },
                                        { label: 'Conférence audio et vidéo', value: 'conf_audio_video' },
                                        { label: 'Call Tracking', value: 'call_tracking' },
                                        { label: 'Autres', value: 'autres' }
                                    ].map((option) => (
                                        <div
                                            className="flex gap-2 align-items-center p-field-checkbox cursor-pointer"
                                            key={option.value}
                                            onClick={() => handleFeatureChange(option.value)} // Handle click on div
                                        >
                                            <Checkbox
                                                id={`feature-${option.value}`}
                                                value={option.value}
                                                checked={selectedFeatures.includes(option.value)}
                                            />
                                            <label className='cursor-pointer' htmlFor={`feature-${option.value}`}>{option.label}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <p className='m-0 mt-3 band-info'>Veuillez sélectionner toutes les fonctionnalités souhaitées, puis appuyez sur "Continuer"</p>
                        <div className="flex pt-4 gap-3 justify-content-between">
                            <Button
                                iconPos="left"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                onClick={() => stepperRef.current.prevCallback()}
                                className="previous-button"
                            />
                            <Button
                                label="Continuer"
                                className='w-full'
                                onClick={() => handleNext(3)}
                                disabled={isArrayEmpty(selectedFeatures)}
                            />
                        </div>
                        <p className='m-0 mt-3'><i className='pi pi-stopwatch'></i> 30 secondes restantes...</p>
                    </StepperPanel>
                    <StepperPanel header="">
                        <div className="flex flex-column">
                            <div className="p-field">
                                <h3 style={{ color: 'black' }}>Quel est le code postal de votre entreprise ?</h3>
                                <InputText
                                    id="postalCode"
                                    keyfilter="num"
                                    placeholder='Code Postal'
                                    value={postalCode}
                                    onChange={handlePostalCodeChange}
                                    className="p-inputtext w-full h-full"
                                    maxLength={5}
                                />
                                <p className='m-0 mt-3 band-info'>Votre code postal nous permet d'identifier les fournisseurs près de chez vous</p>
                            </div>
                        </div>
                        <div className="flex pt-4 gap-3 justify-content-between">
                            <Button
                                iconPos="left"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                onClick={() => stepperRef.current.prevCallback()}
                                className="previous-button"
                            />
                            <Button
                                label="Continuer"
                                className='w-full'
                                onClick={() => handleNext(4)}
                                disabled={postalCode === ''}
                            />
                        </div>
                        <p className='m-0 mt-3'><i className='pi pi-stopwatch'></i> 15 secondes restantes...</p>
                    </StepperPanel>
                    <StepperPanel header="">
                        <div className="flex flex-column">
                            <div className="p-field">
                                <p className='band-info'>Bonne nouvelle ! Nous avons localisé des fournisseurs près de chez vous. Sur quelle adresse email souhaitez vous recevoir des devis ?</p>
                                <h3 style={{ color: 'black' }}><b>Votre adresse email</b></h3>
                                <InputText
                                    id="email"
                                    keyfilter="email"
                                    placeholder='Adresse e-mail'
                                    value={email}
                                    onChange={handleEmailChange}
                                    className="p-inputtext w-full"
                                />
                            </div>
                        </div>
                        <div className="flex pt-4 gap-3 justify-content-between">
                            <Button
                                iconPos="left"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                onClick={() => stepperRef.current.prevCallback()}
                                className="previous-button"
                            />
                            <Button
                                label="Continuer"
                                className='w-full'
                                onClick={() => handleNext(5)}
                                disabled={email === ''}
                            />
                        </div>
                        <p className='m-0 mt-3'><i className='pi pi-stopwatch'></i> 15 secondes restantes...</p>
                    </StepperPanel>
                    <StepperPanel header="">
                        <div className="flex flex-column">
                            <h3 style={{ color: 'black' }}>Nom complet</h3>
                            <div className="flex flex-row gap-6 justify-content-center align-items-center">
                                <div className="p-field">
                                    <InputText
                                        id="firstName"
                                        type="text"
                                        keyfilter="alpha"
                                        placeholder='Prénom'
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        className="p-inputtext w-full"
                                    />
                                </div>
                                <div className="p-field">
                                    <InputText
                                        id="lastName"
                                        type="text"
                                        keyfilter="alpha"
                                        placeholder='Nom'
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                        className="p-inputtext w-full"
                                    />
                                </div>
                            </div>
                            <div className="p-field mt-2">
                                <h3 style={{ color: 'black' }}>Nom de votre entreprise</h3>
                                <InputText
                                    id="companyName"
                                    type="text"
                                    keyfilter="alphanum"
                                    placeholder='Société'
                                    value={companyName}
                                    onChange={handleCompanyNameChange}
                                    className="p-inputtext w-full"
                                />
                            </div>
                        </div>
                        <div className="flex pt-4 gap-3 justify-content-between">
                            <Button
                                iconPos="left"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                onClick={() => stepperRef.current.prevCallback()}
                                className="previous-button"
                            />
                            <Button
                                label="Continuer"
                                className='w-full'
                                onClick={() => handleNext(6)}
                                disabled={firstName === '' || lastName === '' || companyName === ''}
                            />
                        </div>
                        <p className='m-0 mt-3'><i className='pi pi-stopwatch'></i> 10 secondes restantes...</p>
                    </StepperPanel>
                    <StepperPanel header="">
                        <div className="flex flex-column">
                            <div className="p-field">
                                <p className='band-info'>Dernière étape et vous avez terminé.</p>
                                <h3 style={{ color: 'black' }}>Votre numéro de téléphone</h3>
                                <InputText
                                    id="phoneNumber"
                                    keyfilter="num"
                                    maxLength={10}
                                    placeholder='Numéro de téléphone'
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    className="p-inputtext w-full"
                                />
                            </div>
                        </div>
                        <div className="flex gap-3 pt-4">
                            <Button
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                onClick={() => stepperRef.current.prevCallback()}
                            />
                            <Button
                                label="Comparer les offres"
                                className='w-full'
                                onClick={() => handleNext(7)}
                                disabled={phoneNumber.trim() === '' || !/^[\d\s\+\-()]{7,15}$/.test(phoneNumber)} // Validation du numéro de téléphone
                            />
                        </div>
                        <p className='m-0 mt-3'><i className='pi pi-stopwatch'></i> 5 secondes restantes...</p>
                    </StepperPanel>
                </Stepper>
            </div>
        </div>
    );
}
